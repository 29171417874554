import React from "react";

import * as styles from "./Section.module.scss";

const Section = ({ className, classNameInner, children }) => {
    return (
        <div className={styles.section + " " + (className ?? "")} role="group">
            <div className={classNameInner}>{children}</div>
        </div>
    );
};

export default Section;
