import React from "react";
import Section from "../components/Section/Section";
import Layout from "./default";
import { MDXProvider } from "@mdx-js/react";
import Seo from "../components/SEO/SEO";

const shortcodes = { Seo };

const SimpleMDX = ({ children, noSpaceBehindNav }) => {
    return (
        <Layout noSpaceBehindNav={noSpaceBehindNav}>
            <Section>
                <MDXProvider components={shortcodes}>{children}</MDXProvider>
            </Section>
        </Layout>
    );
};

export default SimpleMDX;
